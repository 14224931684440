<template>
  <div id="editPage">
    <div class="flex justify-end">
      <dropdown
          reposition
          searchable
          class="filter-dropdown h-10 ml-auto"
          placeholder="Dashboards"
          :options="dashboardOptions"
          v-model="selectedDashboard"
          :colors="{
            text: '#282828',
            bg: 'white',
            svg: '#282828',
            border: '#e8e8e8',
          }"
          :limit="1"
          width="192px"
          minWidth="102px"
          :config="{ label: 'labelName', trackBy: 'dashboard_id' }"
          @input="selectDashboard"
        />
    </div>
    <!-- <button class="my-3 px-5 py-2 text-sm text-white border-0 outline-none ml-2 bg-blue-600 hover:bg-brand-hover font-medium rounded-lg cursor-pointer  drop-shadow-sm" @click=routeBack()>Back</button> -->
    <div id="dashboard_container" class="mt-3"></div>       
  </div>
</template>

<script>
import { BoldBI } from "@boldbi/boldbi-embedded-sdk";
import BoldBiConfig from "@/boldbi/config";
import biHelper from "@/boldbi";
import VueCookies from "vue-cookies";
import dropdown from "@/components/dropdown-base";
import axios from "@/axios"

function embedSample(dashboardId) {
  let token = VueCookies.get("token");
  const boldBiHeader = {
    Authorization: "Bearer " + token,
  };

  var boldbiEmbedInstance = BoldBI.create({
    serverUrl: BoldBiConfig.BASE_URL,
    dashboardId: dashboardId,
    embedContainerId: "dashboard_container", // This should be the container id where you want to embed the dashboard
    embedType: BoldBI.EmbedType.Component,
    environment: BoldBI.Environment.Enterprise,
    mode: BoldBI.Mode.View,
    height: "100%",
    width: "100%",
    authorizationServer: {
      url: biHelper.generateEmbedUrl(),
      headers: boldBiHeader,
    },
    expirationTime: "100000",
  });
  boldbiEmbedInstance.loadDashboard();
}

export default {
  name: "Bi-Dashboard-View",
  data() {
    return {
      dashboardOptions:[],
      selectedDashboard: []
    };
  },
  components: {
    dropdown
  },

  async mounted() {
    const dashboardId = this.$route.params.id;
    embedSample(dashboardId);
    await this.getTenantDashboards();
  },
  methods: {
    selectDashboard() {
       if (this.selectedDashboard?.name != 'Default Dashboard') {
          this.$router.push({
            name: "Bi-Dashboard-View",
            params: {
              id: this.selectedDashboard.dashboard_id,
            },
          });
          const dashboardId = this.$route.params.id;
          embedSample(dashboardId);
        } else {
          this.$router.push({
            path: "default-dashboard",
            name:"Dashboard - Page 2",
            params: {
              id: process.env.VUE_APP_DEFAULT_DASHBOARD_KEY,
            },
          });
          
        }
    },
    async getTenantDashboards() {
      try {
        const { data } = await axios.get("/dashboard/assigned-dashboards");
        this.dashboardOptions = data.dashboards
        .map((dashboard) => {
          let bbDashboard = dashboard;
          if (dashboard.name.includes("BB_")) {
            bbDashboard.labelName = dashboard.name.split("_")[1];
          } else {
            bbDashboard.labelName = dashboard.name;
          }

          bbDashboard.name = dashboard.name;
          return bbDashboard;
        });
        if (this.$route.params.id) {
          this.selectedDashboard = this.dashboardOptions.find((res)=>res.dashboard_id==this.$route.params.id)
        }
        else {
        this.selectedDashboard = this.dashboardOptions?.[0];
        }
        if (this.selectedDashboard?.name != 'Default Dashboard') {
          this.$router.push({
            name: "Bi-Dashboard-View",
            params: {
              id: this.selectedDashboard.dashboard_id,
            },
          });
        }
        else {
          this.$router.push({
            name: "default-dashboard"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // Function to adjust the iframe height
    adjustIframeHeight() {
      var iframe = document.getElementById("dashboard-frame");
      if (iframe) {
        iframe.height = Math.max(document.documentElement.scrollHeight) + "px";
      }
    },
    routeBack() {
      this.$router.push({ name: "Dashboard - Page 2" });
    },
    // handleDocClick(event) {
    //   if (event?.target?.id != 'dashboard_container_embeddedbi_otheroption' && !this.checkdropdownClick(event?.target)) {
    //     let dropdownElement = document.getElementById('dashboard_container_embeddedbi_ConfigMenu');
    //     if (dropdownElement) {
    //       dropdownElement.style.display = 'none'
    //     }
    //   }
    //   return dropdownClicked;
    // },
    checkdropdownClick(docId) {
      let dropdownClicked = false;
      if (docId?.id == 'dashboard_container_embeddedbi_ConfigMenu' || docId?.parentElement.id == 'export') {
        dropdownClicked = true;
      }
      return dropdownClicked;
    },
  },
  // beforeDestroy() {    
  //   document.removeEventListener('click', this.handleDocClick);
  // },
};
</script>
<style scoped lang="scss">
#dashboard_container {
  width: 100%;
  height: 100%;
}

#editPage {
  width: 100%;
  height: 100%;
}

#list {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid rgba(218, 218, 218, 0.87);
  background: #fff;
  margin-bottom: 12px;
}
::v-deep {
  #dashboard_container_embeddedbi_scrollContentDesignPanel,
  #dashboard_container_embeddedbi_bannerPanel
  {
    z-index: 0 !important;
  }
}
</style>
